<template>
  <Auth :clear="true">
    <section class="page-error-view mt-card">
      <div class="logo-sesi">
        <ApplicationLogo />
      </div>

      <div class="not-authorized-card">
        <span class="icon-page-error mb-2">
          <LockIcon />
        </span>

        <div class="text-center mb-1">
          <h2 class="text-center">Acesso negado</h2>
          <p class="text-center">
            Você não tem permissão para acessar este recurso. Para liberar ou
            verificar seu acesso entre em contato com o suporte.
          </p>
        </div>

        <button
          @click="handleRedirect"
          type="button"
          class="btn button-page-error btn-block"
        >
          {{ getMessageButton }}
        </button>
      </div>
    </section>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import { LockIcon } from "vue-feather-icons";
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import { isUserLoggedIn, setAbility } from "@/auth/utils";

export default {
  components: {
    Auth,
    LockIcon,
    ApplicationLogo,
  },

  data() {
    return {
      sideImg: require("@/assets/images/pages/login-v2.svg"),
    };
  },

  mounted() {
    if (isUserLoggedIn()) {
      setAbility();

      if (this.$route.name == "sair") {
        this.deslogar();
      }
    }
  },

  methods: {
    handleRedirect() {
      if (!isUserLoggedIn()) {
        this.deslogar();
      }

      this.$router.replace({ name: "bem-vindo" });
    },
    deslogar() {
      this.$store.dispatch("sessions/logout");
      this.$router.replace({ name: "auth-login" });
      return false;
    },
  },
  computed: {
    getMessageButton() {
      if (isUserLoggedIn()) {
        return "Voltar para o início";
      }

      return "Ir para o login";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-error.scss";
</style>
